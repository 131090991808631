export const FETCH_LEAVES = "FETCH_LEAVES";
export const HANDLE_SIDEBAR = "HANDLE_SIDEBAR";
export const UPDATE_LEAVE = "UPDATE_LEAVE";
export const UPDATE_DRAG = "UPDATE_DRAG";
export const LEAVE_RESIZE = "LEAVE_RESIZE";
export const HANDLE_SELECTED_LEAVE = "HANDLE_SELECTED_LEAVE";
export const ADD_LEAVE = "ADD_LEAVE";
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const DELETE_LEAVE = "DELETE_LEAVE";

export const LEAVE_STATUS = {
  PRESENT: "present",
  ABSENT: "generalLeave",
  LATE: "late",
  PAID_LEAVE: "paidLeave",
  CASUAL_LEAVE: "casualLeave",
  SICK_LEAVE: "sickLeave",
  ANNUAL_LEAVE: "annualLeave",
  MATERNITY_PATERNITY_LEAVE: "maternityPaternityLeave",
  UNPAID_LEAVE: "unpaidLeave",
};

export const LEAVE_STATUS_OPTIONS = [
  {
    title: "Present",
    value: LEAVE_STATUS?.PRESENT,
  },
  {
    title: "Absent",
    value: LEAVE_STATUS?.ABSENT,
  },
  {
    title: "Late",
    value: LEAVE_STATUS?.LATE,
  },
  {
    title: "Paid Leave",
    value: LEAVE_STATUS?.PAID_LEAVE,
  },

  {
    title: "Casual Leave",
    value: LEAVE_STATUS?.CASUAL_LEAVE,
  },
  {
    title: "Sick Leave",
    value: LEAVE_STATUS?.SICK_LEAVE,
  },
  {
    title: "Annual Leave",
    value: LEAVE_STATUS?.ANNUAL_LEAVE,
  },
  {
    title: "Maternity Paternity Leave",
    value: LEAVE_STATUS?.MATERNITY_PATERNITY_LEAVE,
  },
  {
    title: "Unpaid Leave",
    value: LEAVE_STATUS?.UNPAID_LEAVE,
  },
];

export const LEAVE_COLORS = {
  [LEAVE_STATUS?.ABSENT]: "red",
  [LEAVE_STATUS?.PRESENT]: "green",
  [LEAVE_STATUS?.LATE]: "orange",
  [LEAVE_STATUS?.CASUAL_LEAVE]: "#39bfb9",
  [LEAVE_STATUS?.SICK_LEAVE]: "#c74273",
  [LEAVE_STATUS?.PAID_LEAVE]: "#81b879",
  [LEAVE_STATUS?.ANNUAL_LEAVE]: "#4872a3",
  [LEAVE_STATUS?.UNPAID_LEAVE]: "#7a213f",
  sunday: "gray",
};
